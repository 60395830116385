'use client';

import { PropsWithChildren } from 'react';
import { tenantStoreDi } from '@/global/tenantStore/tenantStoreDi';
import { lakewoodDi } from './_config/lakewood.di';
export default function Template({
  children
}: PropsWithChildren) {
  // Injects the `tenantComponentsMap` on the client side.
  tenantStoreDi.set(lakewoodDi);
  return <>{children}</>;
}