import { List, ListItem, Stack, Typography } from '@mui/material';
export const NoPartMessage = () => <Stack spacing={2} data-sentry-element="Stack" data-sentry-component="NoPartMessage" data-sentry-source-file="NoPartMessage.tsx">
    <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
      {`Don't see the part you're looking for?`}
    </Typography>
    <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
      This demo environment has a limited number of products available for
      testing.
    </Typography>
    <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
      Why don’t you try:
    </Typography>
    <List sx={{
    listStyleType: 'disc',
    pl: 2,
    py: 0,
    '& .MuiListItem-root': {
      display: 'list-item',
      p: 0
    }
  }} data-sentry-element="List" data-sentry-source-file="NoPartMessage.tsx">
      <ListItem data-sentry-element="ListItem" data-sentry-source-file="NoPartMessage.tsx">
        <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
          SU203ML-K15
        </Typography>
      </ListItem>
      <ListItem data-sentry-element="ListItem" data-sentry-source-file="NoPartMessage.tsx">
        <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
          541344
        </Typography>
      </ListItem>
      <ListItem data-sentry-element="ListItem" data-sentry-source-file="NoPartMessage.tsx">
        <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
          QS10.241
        </Typography>
      </ListItem>
    </List>
    <Typography variant="h6" component="p" data-sentry-element="Typography" data-sentry-source-file="NoPartMessage.tsx">
      {`If this happens on your real site, we'll encourage customers to chat with
      you.`}
    </Typography>
  </Stack>;